import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_widgets_faq = _resolveComponent("widgets-faq")!
  const _component_widgets_empty = _resolveComponent("widgets-empty")!
  const _component_widgets_filter = _resolveComponent("widgets-filter")!
  const _component_widgets_table = _resolveComponent("widgets-table")!
  const _component_chats_settings_page = _resolveComponent("chats-settings-page")!

  return (_openBlock(), _createBlock(_component_chats_settings_page, { breadcrumbs: _ctx.breadcrumbs }, _createSlots({
    "page-content-white": _withCtx(() => [
      (_ctx.isEmptyMode)
        ? (_openBlock(), _createBlock(_component_widgets_empty, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_widgets_filter, { selected: _ctx.selected }, null, 8, ["selected"]),
            _createVNode(_component_widgets_table, {
              modelValue: _ctx.selected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
              headers: _ctx.fieldsHeaders,
              items: _ctx.webWidgetsTable,
              "item-key": "name",
              "items-name": "widgets",
              "show-selection": ""
            }, null, 8, ["modelValue", "headers", "items"])
          ], 64))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: "Web widgets",
        class: "mb-4",
        icon: "tmi-comment"
      })
    ]),
    _: 2
  }, [
    (_ctx.isEmptyMode)
      ? {
          name: "after-content-white",
          fn: _withCtx(() => [
            _createVNode(_component_widgets_faq, { class: "mt-10" })
          ])
        }
      : undefined
  ]), 1032, ["breadcrumbs"]))
}