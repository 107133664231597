
import { defineComponent, ref } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import WidgetsEmpty from '@/components/pages/chats/settings/channels/web-widgets/WidgetsEmpty.vue'
import { useModes } from '@/compositions/modes'
import WidgetsFilter from '@/components/pages/chats/settings/channels/web-widgets/WidgetsFilter.vue'
import { webWidgetsTable } from '@/definitions/chats/settings/web-widget/data'
import WidgetsTable from '@/components/pages/chats/settings/channels/web-widgets/WidgetsTable.vue'
import WidgetsFaq from '@/components/pages/chats/settings/channels/web-widgets/WidgetsFaq.vue'

export default defineComponent({
  components: {
    WidgetsFaq,
    WidgetsTable,
    WidgetsFilter,
    WidgetsEmpty,
    DetailsHero,
    ChatsSettingsPage,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Messenger settings', url: { name: 'base.chats.settings' } },
      { label: 'Web widgets' },
    ]
    const selected = ref([])
    const fieldsHeaders = ref([
      { text: 'Name', align: 'start', value: 'name' },
      { text: 'Domain', value: 'domain', hideSortable: true },
      { text: 'Embed code', value: 'code', width: '40%', hideSortable: true },
      { text: 'Status', value: 'status' },
      { text: 'Last updated', value: 'lastUpdated' },
    ])

    return {
      selected,
      fieldsHeaders,
      webWidgetsTable,
      breadcrumbs,
      isEmptyMode,
    }
  },
})
